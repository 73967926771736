import React, { useEffect, useCallback, useContext, useState } from "react";
import {
	GoogleReCaptcha,
	GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import GlobalContext from "../context/GlobalContext";
import useFormFieldValidation from "../hook/useFormFieldValidation";
import LogoName from "../components/logoname/LogoName";
import TitleSubSection from "../components/titlecomponent/TitleSubSection";
import RequestContext from "../context/RequestContext";
import ModalLoading from "../components/modals/modalloading/ModalLoading";
import LoadingModal from "../backend/component/authorizationcomponent/LoadingModal";
import useUser from "../hook/useUser";
import useScreenSize from "../hook/useScreemSize";
import { CONTACT_FORM, URL_IMG } from "../const/Const";
import { LanguageButton } from "../components/language/LanguageButton";
import LanguageContext from "../context/LanguageContext";
import useRequest from "../hook/useRequest";
import Header from "../components/header/Header";

const ContactForm = () => {
	const { loading, message, errorHttp } = useContext(RequestContext);
	const { setLoading } = useUser();
	const { contactForm } = useRequest();

	const [tokenGoogle, setTokenGoogle] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const { width } = useScreenSize();
	const { handleLanguageButton } = useContext(LanguageContext);

	const {
		formErrors,
		formData,
		setFormData,
		validateField,
		initialValues,
		isSubmitDisabled,
	} = useFormFieldValidation();

	// const testsize = "t4";

	const onVerify = useCallback(
		(tokenData) => {
			setTokenGoogle(tokenData);
		},
		[setTokenGoogle]
	);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleBlur = (e) => {
		const { name: inputName, value } = e.target;
		validateField(inputName, value);
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		// Hacemos una pausa de 3 segundos antes de enviar el formulario
		await new Promise((r) => setTimeout(r, 3000));

		// if (formData.surname) {
		// 	// console.log("False submit success!");
		// 	// setSubmitSuccess(true);
		// 	setFormData(initialValues);
		// 	setFormErrors(initialErrors);
		// 	return;
		// }

		// Comprobar si hay errores en los campos de entrada
		const hasErrors = Object.values(formErrors).every(
			(error) => error === ""
		);
		//quitamos el surname del array para que sirva de honeypot
		// const formDataWithoutSurname = { ...formData };
		// delete formDataWithoutSurname.surname;

		if (hasErrors) {
			// Ejecutar la acción onSubmit si no hay errores
			contactForm(
				CONTACT_FORM,
				"POST",
				{
					name: formData.name,
					email: formData.email,
					message: formData.message,
					surname: formData.surname,
					tokenGoogle: tokenGoogle,
				},
				null
			);
		} else {
		}

		setFormData(initialValues);
		setRefreshReCaptcha((r) => !r);
	};

	const imageUrl = `${URL_IMG}/web/contact/contact${
		width <= 1025 ? "_mobile" : ""
	}-min.jpg`;

	return (
		<GoogleReCaptchaProvider reCaptchaKey="6LdX98QpAAAAAF0hyPnajgM4opyn2V_VbIPhaMwN">
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<Header />
			<div className="main">
				{(loading || errorHttp) && (
					<ModalLoading
						elementById="modal"
						loading={loading}
						setLoading={setLoading}
					>
						<LoadingModal message={message} titleModal="Cargando" />
					</ModalLoading>
				)}
				{width <= 1025 ? (
					<div className="mainContact">
						<div className="contentTextContact">
							<h2>¡Bienvenido!</h2>{" "}
							<p className="textContact">
								Estamos encantados de poder conectarnos contigo
								y responder a cualquier pregunta que tengas.{" "}
								<span>Si necesitas más información</span> sobre
								nuestros productos, servicios{" "}
								<span>¡este es el lugar adecuado!</span> Nos
								esforzamos por brindarte la mejor experiencia
								posible, así que no dudes en hacernos saber cómo
								podemos ayudarte.{" "}
								<span>Tu opinión es muy importante</span> para
								nosotros.{" "}
							</p>
							<h2>¡Gracias por confiar en nosotros!</h2>
						</div>
						<div className="contentPicture">
							<img
								className="imageContact"
								src={imageUrl}
								alt="Foto contacto"
							/>
						</div>
						<div className="contentContact">
							<div className="containerContact df-s jcc-s aic-s">
								<div className="cardForm mb3-s">
									<div className="contentTitle mt16-s">
										<h2>¡Escribenos y hablamos!</h2>
										<p className="messageContact">
											{" "}
											Rellena este formulario para saber
											tus necesidades y te responderemos{" "}
											<span>en menos de 72h</span>
										</p>
									</div>

									<form>
										<div className="content-input mb8-s dg-s fww-s">
											<label className="form-label">
												Nombre
											</label>
											<input
												type="text"
												id="name"
												name="name"
												className={
													formErrors.name
														? "w100-s border-danger"
														: "w100-s"
												}
												onChange={handleChange}
												onBlur={handleBlur}
												value={formData.name}
												autoComplete="Name"
												placeholder="Nombre"
											/>
											<div className="content-danger">
												{formErrors.name && (
													<div className="text-danger">
														{formErrors.name}
													</div>
												)}
											</div>
										</div>
										<div className="content-input mb8-s dg-s fww-s">
											<label
												className="form-label"
												htmlFor="form-label"
											>
												Correo Electrónico
											</label>
											<input
												type="email"
												id="email"
												name="email"
												className={
													formErrors.email
														? "w100-s border-danger"
														: "w100-s"
												}
												onChange={handleChange}
												onBlur={handleBlur}
												value={formData.email}
												placeholder="Cuenta de correo"
											/>
											<div className="content-danger">
												{formErrors.email && (
													<p className="text-danger">
														{formErrors.email}
													</p>
												)}
											</div>
										</div>
										<div className="content-input mb8-s dg-s fww-s">
											<label className="form-label">
												Mensaje
											</label>
											<textarea
												id="message"
												name="message"
												className={
													formErrors.message
														? "w100-s border-danger"
														: "w100-s"
												}
												onChange={handleChange}
												onBlur={handleBlur}
												value={formData.message}
												placeholder="Escribe tu mensaje"
											/>
											<div className="content-danger">
												{formErrors.message && (
													<div className="text-danger">
														{formErrors.message}
													</div>
												)}
											</div>
										</div>
										<div className="content-input mb8-s dg-s fww-s dn-s">
											<label className="form-label">
												Apellidos
											</label>
											<input
												type="text"
												name="surname"
												onChange={handleChange}
												value={formData.surname}
											/>
											<div className="content-danger">
												{formErrors.surname && (
													<p className="text-danger">
														{formErrors.surname}
													</p>
												)}
											</div>
										</div>

										<div>
											<GoogleReCaptcha
												onVerify={onVerify}
												refreshReCaptcha={
													refreshReCaptcha
												}
											/>
										</div>
										<button
											type="submit"
											className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
											disabled={isSubmitDisabled()}
											onClick={onSubmit}
										>
											Enviar
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="mainContact">
						<div className="contentTextContact">
							<h2>¡Bienvenido!</h2>{" "}
							<p className="textContact">
								Estamos encantados de poder conectarnos contigo
								y responder a cualquier pregunta que tengas.{" "}
								<span>Si necesitas más información</span> sobre
								nuestros productos, servicios{" "}
								<span>¡este es el lugar adecuado!</span> Nos
								esforzamos por brindarte la mejor experiencia
								posible, así que no dudes en hacernos saber cómo
								podemos ayudarte.{" "}
								<span>Tu opinión es muy importante</span> para
								nosotros.{" "}
							</p>
							<h2>¡Gracias por confiar en nosotros!</h2>
						</div>
						<div className="contentPicture">
							<div
								style={{
									backgroundImage: `url(${imageUrl})`,
								}}
								className="imageContact w100-s"
							>
								<div className="contentContact">
									<div className="containerContact df-s jcc-s aic-s">
										<div className="cardForm mb3-s">
											<div className="card-body"></div>
											<div className="contentTitle mt16-s">
												<h2>¡Escribenos y hablamos!</h2>
												<p className="messageContact">
													{" "}
													Rellena este formulario para
													saber tus necesidades y te
													responderemos{" "}
													<span>en menos de 72h</span>
												</p>
											</div>
											<form>
												<div className="content-input mb8-s dg-s fww-s">
													<label className="form-label">
														Nombre
													</label>
													<input
														type="text"
														id="name"
														name="name"
														className={
															formErrors.name
																? "w100-s border-danger"
																: "w100-s"
														}
														onChange={handleChange}
														onBlur={handleBlur}
														value={formData.name}
														autoComplete="Name"
														placeholder="Nombre"
													/>
													<div className="content-danger">
														{formErrors.name && (
															<div className="text-danger">
																{
																	formErrors.name
																}
															</div>
														)}
													</div>
												</div>
												<div className="content-input mb8-s dg-s fww-s">
													<label
														className="form-label"
														htmlFor="form-label"
													>
														Correo Electrónico
													</label>
													<input
														type="email"
														id="email"
														name="email"
														className={
															formErrors.email
																? "w100-s border-danger"
																: "w100-s"
														}
														onChange={handleChange}
														onBlur={handleBlur}
														value={formData.email}
														placeholder="Cuenta de correo"
													/>
													<div className="content-danger">
														{formErrors.email && (
															<p className="text-danger">
																{
																	formErrors.email
																}
															</p>
														)}
													</div>
												</div>

												<div className="content-input mb8-s dg-s fww-s">
													<label className="form-label">
														Mensaje
													</label>
													<textarea
														id="message"
														name="message"
														className={
															formErrors.message
																? "w100-s border-danger"
																: "w100-s"
														}
														onChange={handleChange}
														onBlur={handleBlur}
														value={formData.message}
														placeholder="Escribe tu mensaje"
													/>
													<div className="content-danger">
														{formErrors.message && (
															<div className="text-danger">
																{
																	formErrors.message
																}
															</div>
														)}
													</div>
												</div>
												<div className="content-input mb8-s dg-s fww-s dn-s">
													<label className="form-label">
														Apellidos
													</label>
													<input
														type="text"
														name="surname"
														onChange={handleChange}
														value={formData.surname}
													/>
												</div>
												<div>
													<GoogleReCaptcha
														onVerify={onVerify}
														refreshReCaptcha={
															refreshReCaptcha
														}
													/>
												</div>
												<button
													type="submit"
													className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
													disabled={isSubmitDisabled()}
													onClick={onSubmit}
												>
													Enviar
												</button>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</GoogleReCaptchaProvider>
	);
};

export default ContactForm;
