import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import doFetch from "../service/doFetch";
import RequestContext from "../context/RequestContext";
import GlobalContext from "../context/GlobalContext";
import SetItemResponse from "../components/utilities/SetItemResponse";
import useUpdateState from "./useUpdateState";
import LoginContext from "../context/LoginContext";

const useRequest = () => {
	const { setUserToken, setUserData } = useContext(LoginContext);

	const { setData, setVersionApp } = useContext(GlobalContext);

	const { setMessage, setCode, setLoading } = useContext(RequestContext);

	const { delStateStorage } = useUpdateState();

	const navigate = useNavigate();

	const getAllData = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body, token)
				.then((response) => {
					if (Boolean(response) && response.length !== 0) {
						setData(response);
						SetItemResponse(response, "data", 30, "second");
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["data"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setData, setCode, setMessage, delStateStorage, setLoading]
	);

	const getVersion = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body, token)
				.then((response) => {
					if (Boolean(response) && response.length !== 0) {
						setVersionApp(response);
						SetItemResponse(response, "versionapp", 30, "second");
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["versionapp"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setCode, setMessage, delStateStorage, setVersionApp, setLoading]
	);

	const contactForm = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body)
				.then((response) => {
					const { Code, Message } = response;
					if (Code === 202 /*&& User.role_id === 1*/) {
						setMessage(Message);
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["versionapp"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setCode, setMessage, delStateStorage, setLoading]
	);

	const twoFaForm = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body, token)
				.then((response) => {
					const { Token, Code, User, Message, Redirect_to } =
						response;
					if (
						Code === 202/*&& User.role_id === 1*/
					) {
						setUserData(User);
						window.sessionStorage.setItem(
							"usrd",
							JSON.stringify(User)
						);
						setUserToken(Token);
						window.sessionStorage.setItem(
							"usrt",
							JSON.stringify(Token)
						);
						// navigate("/dashboard")
					} else if (Code === 401) {
						setMessage(Message);
						setCode(Code);
					} else if (Redirect_to) {
						navigate(Redirect_to);
					} /*else if (Code === 202 && User.role_id !== 1) {
						setLoading(false);
						setMessage("Acceso denegado");
						setCode(Code);
						setUserData(null);
						setUserToken(null);
					}*/
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["versionapp"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[
			setCode,
			setMessage,
			delStateStorage,
			setLoading,
			setUserData,
			navigate,
			setUserToken,
		]
	);

	return { getAllData, getVersion, contactForm, twoFaForm };
};

export default useRequest;
