import React, { useContext } from "react";
import ModalLoading from "../../components/modals/modalloading/ModalLoading";
import LogoName from "../../components/logoname/LogoName";
import TitleSubSection from "../../components/titlecomponent/TitleSubSection";
import LoadingModal from "../component/authorizationcomponent/LoadingModal";
import RequestContext from "../../context/RequestContext";
import useFormFieldValidation from "../../hook/useFormFieldValidation";
import { useLocation } from "react-router-dom";
import { TWO_FA_FORM } from "../../const/Const";
import useRequest from "../../hook/useRequest";

const TwofaCode = () => {
	const {
		formErrors,
		formData,
		setFormData,
		validateField,
		initialValues,
		isSubmitDisabled,
	} = useFormFieldValidation();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tokenFromUrl = searchParams.get("token");
	const { twoFaForm } = useRequest();

	const { loading, setLoading, message, errorHttp } =
		useContext(RequestContext);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleBlur = (e) => {
		const { name: inputName, value } = e.target;
		validateField(inputName, value);
	};


	const onSubmit = (event) => {
		event.preventDefault();

		const hasErrors = Object.values(formErrors).every(
			(error) => error === ""
		);

		if (hasErrors) {
			// Ejecutar la acción onSubmit si no hay errores
			twoFaForm(
				TWO_FA_FORM,
				"POST",
				{
					code: formData.twofacode,
				},
				tokenFromUrl
			);
		} else {
		}

		setFormData(initialValues);

		setFormData(initialValues);
	};

	return (
		<div className="mainForm">
			{(loading || errorHttp) && (
				<ModalLoading
					elementById="modal"
					loading={loading}
					setLoading={setLoading}
				>
					<LoadingModal message={message} titleModal="Cargando" />
				</ModalLoading>
			)}
			<div className="containerForm df-s jcc-s aic-s">
				<div className="cardForm mb3-s">
					<div className="card-body">
						<LogoName />
						<div className="contentTitle mt16-s">
							<TitleSubSection
								title="Doble autentificación"
								sizetext="t4"
							/>
						</div>
						<form>
							<div className="content-input mb8-s dg-s fww-s">
								<label className="form-label">Código</label>
								<input
									type="text"
									name="twofacode"
									className={
										formErrors.name
											? "w100-s border-danger"
											: "w100-s"
									}
									title="Ingresa un código válido"
									placeholder="Ingresar código"
									onChange={handleChange}
									onBlur={handleBlur}
									value={formData.twofacode}
									// autoComplete="twofacode"
								/>
							</div>
							<div className="content-danger">
								{formErrors.twofacode && (
									<div className="text-danger">
										{formErrors.twofacode}
									</div>
								)}
							</div>
							<div className="df-s jcc-s m16-s ">
								<button
									className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
									disabled={isSubmitDisabled(["twofacode"])}
									onClick={onSubmit}
								>
									Aceptar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TwofaCode;
