import React, { useContext } from "react";
import LogoName from "../logoname/LogoName";
import Avatar from "../avatar/Avatar";
import LoginContext from "../../context/LoginContext";
import { Link } from "react-router-dom";

const Header = () => {
	const { userData, isAuth } = useContext(LoginContext);

	return (
		<header className="headerIntranet full">
			<div className="headerSup g-container gtc2-s gtc3-m">
				<div className="seccionLogo gces1-s gces2-m jcs-s dg-s">
					<Link to="/">
						<LogoName />
					</Link>
				</div>
				{isAuth === "isLogged" && <Avatar name={userData.name} />}
			</div>
			{/* <div className="headerDown g-container acc-s">
				<div className="subtitle center-full bigger">
					Panel de control
				</div>
			</div> */}
		</header>
	);
};

export default Header;
